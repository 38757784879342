export default defineNuxtPlugin((nuxtApp) => {
  const CMPID_COOKIE_LABEL = 'cmpid';
  const REF_COOKIE_LABEL = '__april_ref';
  const LAN_COOKIE_LABEL = '__april_lan';
  const route = useRoute();
  const { cmpid } = route.query;
  const options = {
    domain: '.april.fr',
    sameSite: 'none',
    secure: true,
    path: '/',
  };

  const aprilRefCookie = useCookie(REF_COOKIE_LABEL, options);
  const aprilLanCookie = useCookie(LAN_COOKIE_LABEL, options);
  const cmpidCookie = useCookie(CMPID_COOKIE_LABEL, options);

  const shouldUpdateCookie = (cookie) => {
    if (!document?.referrer) return true;

    const isAprilReferrer = document.referrer.includes(options.domain);
    const shouldUpdate = !isAprilReferrer || !cookie;

    return shouldUpdate;
  };

  const cmpidCookieValue = shouldUpdateCookie(cmpidCookie.value)
    ? cmpid
    : cmpidCookie.value;

  const refCookieValue = shouldUpdateCookie(aprilRefCookie.value)
    ? encodeURIComponent(document.referrer)
    : aprilRefCookie.value;

  cmpidCookie.value = cmpidCookieValue;
  aprilRefCookie.value = refCookieValue;
  aprilLanCookie.value = encodeURIComponent(document?.location?.href ?? '');

  return {
    provide: {
      cookies: {
        get: (cookieName) => {
          if (cookieName === CMPID_COOKIE_LABEL) return cmpidCookie.value;
          if (cookieName === REF_COOKIE_LABEL) return aprilRefCookie.value;
          if (cookieName === LAN_COOKIE_LABEL) return aprilLanCookie.value;
          else return undefined;
        },
      },
    },
  };
});
