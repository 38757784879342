
// @ts-nocheck


export const localeCodes =  [
  "fr"
]

export const localeLoaders = {
  "fr": []
}

export const vueI18nConfigs = [
  () => import("../i18n.config.ts?hash=bffaebcb&config=1" /* webpackChunkName: "i18n_config_bffaebcb" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": false,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [
    {
      "code": "fr",
      "corporate": "fr",
      "language": "fr-fr"
    }
  ],
  "defaultLocale": "fr",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": false,
  "langDir": null,
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": false
  },
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "messages": {
    "fr": {
      "cta": {
        "en-savoir-plus": "En savoir plus",
        "en-savoir-plus-aria": "En savoir plus sur : {title}",
        "obtenir-mon-tarif": "Obtenir mon tarif",
        "obtenir-un-tarif": "Obtenir un tarif",
        "voir-tous-les-avis": "Voir tous les avis",
        "voir-tous-les-contacts": "Voir tous les contacts",
        "demander-devis": "Demander un devis",
        "demande-de-rappel": "Demande de rappel",
        "demande-devis-par-mail": "Demande devis par mail",
        "souscrire": "Souscrire en ligne",
        "send": "Envoyer",
        "close": "Fermer"
      },
      "configs": {
        "head": {
          "description": "Découvrez les contrats d'assurance APRIL : assurance de prêt immobilier, complémentaire santé, assurance auto - auto malus, habitation, prévoyance.",
          "title": "APRIL : Assurance prêt, santé, auto, habitation, prévoyance",
          "siteName": "APRIL",
          "image": "https://assets.april.fr/prismic/april.jpg?vh=c2f080"
        }
      },
      "global": {
        "logo": "April",
        "backToTop": "Retour en haut de page",
        "price-unit": "€",
        "icon": "Icône {title}"
      },
      "header": {
        "switcher": {
          "private": "Particuliers",
          "professional": "Pro & Entreprises"
        },
        "seeAll": "Voir les produits {0}",
        "title": "Notre produit <small>(1)</small> | Nos produits <small>({count})</small>"
      },
      "slider": {
        "next": "Suivant",
        "previous": "Précédent"
      },
      "accordion": {
        "open": "Ouvrir le panneau"
      },
      "navigation": {
        "menu": {
          "label": "Menu du site",
          "close": "Fermer la navigation"
        },
        "switcher": {
          "notification": ""
        }
      },
      "smartButton": {
        "close": "Fermer la liste"
      },
      "article": {
        "reading-time": "{readingTime}min",
        "published": "Publié le {date}",
        "all-articles": "Toutes les actualités",
        "all-articles-market": "Toutes les actualités {market}"
      },
      "product": {
        "a-partir": "À partir de",
        "par-mois": "/ mois"
      },
      "breadcrumbs": {
        "label": "fil d'Ariane",
        "home": "Assurances APRIL",
        "home-pro": "Assurances professionnelles APRIL",
        "contact": "Contact",
        "sitemap": "Plan du site",
        "faq": "FAQ",
        "lexicon": "Lexique",
        "default": "Page April",
        "default-lexicon": "Lexique de l'assurance",
        "default-article": "Article April",
        "default-guide": "Guide April"
      },
      "summary": {
        "title": "Sommaire",
        "label": "summary"
      },
      "remainingValue": {
        "amountValue": "Il vous reste <h3>{amount}</h3> à rembourser"
      },
      "mailto": {
        "default": {
          "subject": "Demande Site {0}",
          "interest": {
            "title": "Intérêt :",
            "entries": {
              "market": "Marché : {0}",
              "product": "Produit : {0}"
            }
          },
          "contact": {
            "title": "Contact :",
            "entries": {
              "company": "Nom de l’entreprise : {0}",
              "member": "Numéro Adhérent : {0}",
              "civility": "Civilité : {0}",
              "name": "Nom : {0}",
              "firstname": "Prénom : {0}",
              "phone": "Tel : {0}",
              "email": "Email : {0}",
              "contact": "Filiale : {0}",
              "subject": "Motif : {0}",
              "message": "Message client : {0}",
              "address": "Adresse client : {0}",
              "zipcode": "Code Postal client : {0}",
              "city": "Ville client : {0}",
              "country": "Pays client : {0}",
              "numeroOrias": "Numéro Orias : {0}",
              "numeroSiren": "Numéro SIREN : {0}",
              "offres": "Offres client : {0}",
              "nomParrain": "Nom Parrain : {0}",
              "prenomParrain": "Prénom Parrain : {0}",
              "emailParrain": "Email Parrain : {0}",
              "numAdherentParrain": "Numéro Adhérent Parrain : {0}",
              "nomFilleul": "Nom Filleul : {0}",
              "prenomFilleul": "Prénom Filleul : {0}",
              "dateFilleul": "Date de naissance Filleul : {0}",
              "codePostalFilleul": "Code Postal Filleul : {0}",
              "telFilleul": "Tel Filleul : {0}",
              "emailFilleul": "Email Filleul : {0}",
              "villeFilleul": "Ville Filleul : {0}",
              "precisions": "Précisions Parrainaige : {0}",
              "nom": "Nom : {0}",
              "activitesExercees": "Activités exercées : {0}",
              "chiffreAffaires": "Chiffre d'affaires : {0}",
              "dateCreationEntreprise": "Date de création d'entreprise: {0}",
              "telMobile": "Tel: {0}",
              "civiliteprospect": "Civilité : {0}",
              "prenomprospect": "Prénom: {0}",
              "nomprospect": "Nom: {0}",
              "cpprospect": "Code Postal: {0}",
              "telprospect": "Tel: {0}",
              "emailprospect": "Email: {0}",
              "adresseprospect": "Adresse: {0}",
              "champaprospect": "Contrat April: {0}",
              "champbprospect": "Fonction: {0}",
              "champfprospect": "Intéret: {0}",
              "villeprospect": "Ville: {0}",
              "commentprospect": "Commentaire: {0}",
              "effectifprospect": "Effectif: {0}",
              "societeprospect": "Société: {0}",
              "contratFilleul": "Contrat : {0}",
              "contratParrain": "N° adhérent du parrain : {0}",
              "messageDate": "Date: {0}"
            }
          },
          "footer": "Cordialement,<br><br>L'équipe april.fr"
        },
        "parrainage": {
          "subject": "Demande de parrainage APRIL",
          "information": "Cher partenaire,<br><br>Votre client, {0} {1}, souhaite recommander APRIL Santé Prévoyance à la personne suivante :",
          "footer": "<p>Nous vous remercions de recontacter ce prospect afin de lui proposer un contrat adapté à ses besoins.</p><p>Dès la prise d’effet des garanties, votre client et son filleul pourront recevoir leurs récompenses, soit grâce à notre nouvelle offre de parrainage :</p><ul style='padding: 0' align='left' type='disc'><li>20 € : pour un contrat en santé individuelle</li><li>50 € : pour un contrat emprunteur</li></ul><p>Nous restons à votre disposition pour tout complément d’information.</p><p>Cordialement,</p><p>L'équipe Expérience Client<br>APRIL Santé Prévoyance</p>",
          "legals": "<p style='font-size:80%'>APRIL Santé Prévoyance - Immeuble Aprilium - 114 boulevard Vivier Merle - 69439 LYON Cedex 03 - www.april.fr S.A.S.U. au capital de 540 640€ - RCS LYON 428 702 419 – Intermédiaire en assurances immatriculé à l’ORIAS sous le n°07 002 609 (hwww.orias.fr ) Autorité de contrôle prudentiel et de résolution – 4 place de Budapest – CS 92459 – 75436 Paris CEDEX 09</p>"
        },
        "pre-sale": {
          "subject": "Demande d’informations/Devis Site {0}",
          "intro": "Un prospect issu du site {0} est intéressé par une offre. Merci de bien vouloir le recontacter."
        },
        "after-sales": {
          "subject": "Demande de contact {0}",
          "intro": "Un client issu du site {0} souhaite être rappelé. Merci de bien vouloir le recontacter. "
        },
        "claim": {
          "subject": "Réclamation client {0}",
          "intro": "Un client issu du site {0} souhaite vous faire part d’une réclamation. Merci de bien vouloir en prendre connaissance et le recontacter. "
        },
        "question": {
          "subject": "Question utilisateur du site {0}",
          "intro": "Un utilisateur du site {0} a une question concernant la page {1}.<br>Pour publier la question sur la page ainsi que la réponse associée, rendez-vous dans le back-office du site à l’adresse suivante <a href='https://april-direct.prismic.io/documents/working/'>https://april-direct.prismic.io/documents/working/</a>."
        }
      },
      "ratingsInfo": {
        "lite": {
          "label": "{count} avis clients sur <a href='{href}' target='_blank' >eKomi</a>"
        }
      }
    }
  },
  "warnHtmlInMessage": "off",
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "fr",
    "corporate": "fr",
    "language": "fr-fr",
    "files": []
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
