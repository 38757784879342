<template>
  <img
    v-lazy="isLazy ? true : null"
    :loading="isLazy ? 'lazy' : null"
    :data-srcset="isLazy && !hasSingleSrc ? srcset : null"
    :data-src="isLazy && hasSingleSrc ? filename : null"
    :srcset="!isLazy && !hasSingleSrc ? srcset : null"
    :src="
      !isLazy && !hasSingleSrc
        ? srcset
        : 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='
    "
    :sizes="sizes"
    :alt="alt"
    :width="width"
    :height="height"
    class="fluid-img lazyload"
  />
</template>

<script>
import {
  buildFluidSrcSet,
  buildFixedSrcSet,
  buildSingleSrcSet,
  commonProps,
} from './utils';

export default {
  props: {
    ...commonProps,
    lazy: {
      type: Boolean,
      required: false,
      default: true,
    },
    alt: {
      type: String,
      required: false,
      default: '',
    },
    height: {
      type: Number,
      required: true,
      default: null,
    },
    width: {
      type: Number,
      required: true,
      default: null,
    },
  },
  computed: {
    isFixed() {
      return this.$parent.fixed || this.fixed;
    },
    isLazy() {
      return (
        (typeof this.$parent.lazy !== 'undefined' && this.$parent.lazy) ||
        this.lazy
      );
    },
    hasSingleSrc() {
      return this.$parent.singleSrc || this.singleSrc;
    },
    src() {
      if (!this.filename) {
        return null;
      }

      return buildSingleSrcSet(this.filename);
    },
    srcset() {
      if (!this.filename) {
        return null;
      }

      if (this.hasSingleSrc) {
        return buildSingleSrcSet(
          this.filename,
          this.$parent.width || this.width
        );
      }

      return this.isFixed
        ? buildFixedSrcSet(this.filename, this.$parent.width || this.width)
        : buildFluidSrcSet(this.filename, this.srcSetWidths);
    },
  },
};
</script>

<style>
img.fluid-img {
  width: 100%;
  height: auto;
}
</style>
