import { richText, stripRichTextWrapperTag } from '../../utils/html';
import { adapter as imageAdapter } from '../../components/cloud-image/utils';
import getPrice from '../../utils/get-price';

const productBase = (product) => {
  if (!product.data) {
    return {};
  }

  let card = product.data;

  if (product.type !== 'product') {
    card = (product.data?.card && product.data?.card[0]) || {};
  }

  return {
    name: product.data.tag,
    title: card.card_title ? stripRichTextWrapperTag(card.card_title) : null,
    image: card.card_image
      ? imageAdapter(card.card_image, card.card_image_alt)
      : null,
  };
};

export const productThumb = (product, linkSerializer) => {
  const { data } = product;
  if (!data) {
    return null;
  }

  return {
    ...productBase(product),
    link: linkSerializer(product),
  };
};

const getSecondaryLinkData = (
  ctaSecondaryLink,
  ctaSecondaryLabel,
  ctaSecondaryDocument,
  ctaSecondaryRelAttributes,
  linkSerializer,
  i18n
) => {
  let secondaryLink = ctaSecondaryLink;

  if (ctaSecondaryDocument) {
    secondaryLink = ctaSecondaryDocument;
  }

  if (linkSerializer(secondaryLink)) {
    secondaryLink.rel = ctaSecondaryRelAttributes;
    return {
      label: ctaSecondaryLabel || i18n.t('cta.en-savoir-plus'),
      link: linkSerializer(secondaryLink),
    };
  }

  return null;
};

export const productFull = (product, i18n, linkSerializer) => {
  const { data } = product;
  const isStandalone = product.type === 'product';
  if (!data) {
    return null;
  }

  let card = product.data;

  if (!isStandalone) {
    card = (product.data?.card && product.data?.card[0]) || {};
  }

  const primaryLink =
    card.cta_primary_link && linkSerializer(card.cta_primary_link);

  const secondaryLinkData =
    product?.id === card?.cta_secondary_link?.id
      ? { ...product, link_type: 'Document' }
      : card.cta_secondary_link;

  const secondaryLink = secondaryLinkData
    ? getSecondaryLinkData(
        secondaryLinkData,
        card.cta_secondary_label,
        card.cta_secondary_document,
        card.cta_secondary_link_rel,
        linkSerializer,
        i18n
      )
    : null;

  return {
    ...productBase(product),
    content: data.card_description ? richText(data.card_description) : null,
    price:
      data.starting_price &&
      getPrice(
        data.starting_price,
        data.price_frequency,
        data.legal_reference,
        i18n,
        {
          twoLines: true,
          bold: true,
        }
      ),
    primaryLink:
      primaryLink && card.cta_primary_label
        ? {
            label: card.cta_primary_label,
            link: primaryLink,
          }
        : null,
    secondaryLink,
  };
};
