export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"name":"theme-color","content":"#ffffff"},{"http-equiv":"X-UA-Compatible","content":"IE=edge"},{"name":"referrer","content":"no-referrer-when-downgrade"},{"hid":"robots","name":"robots","content":"index, follow"},{"name":"robots","content":"max-video-preview:3"},{"name":"robots","content":"max-image-preview:standard"},{"name":"robots","content":"max-snippet:-1"},{"property":"og:type","content":"website"},{"property":"og:image:type","content":"image/jpeg"},{"property":"og:image:width","content":"1880"},{"property":"og:image:height","content":"984"},{"name":"twitter:card","content":"summary"},{"name":"mobile-web-app-capable","content":"yes"},{"name":"apple-mobile-web-app-capable","content":"yes"},{"name":"apple-mobile-web-app-status-bar-style","content":"black-translucent"}],"link":[{"rel":"icon","type":"image/svg+xml","href":"/favicons/favicon.svg"},{"rel":"alternate icon","href":"/favicons/favicon.ico"},{"rel":"mask-icon","href":"/favicons/mask-icon.svg","color":"#003c61"},{"rel":"apple-touch-icon","href":"/favicons/apple-touch-icon.png"},{"rel":"manifest","href":"/manifest.json"},{"rel":"preconnect","href":"//www.google-analytics.com/","crossorigin":""},{"rel":"preconnect","href":"//www.googletagmanager.com/","crossorigin":""},{"rel":"preconnect","href":"//assets.april.fr/","crossorigin":""},{"rel":"preconnect","href":"//static.axept.io/","crossorigin":""},{"rel":"preconnect","href":"//try.abtasty.com","crossorigin":""}],"style":[],"script":[],"noscript":[],"title":""}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = false

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false

export const chunkErrorEvent = "vite:preloadError"