import { default as PrismicPreviewJ01p9R2IH8Meta } from "/vercel/path0/node_modules/@nuxtjs/prismic/dist/runtime/PrismicPreview.vue?macro=true";
import { default as indexJCwIUzAPrYMeta } from "/vercel/path0/pages/[market]/[product]/index.vue?macro=true";
import { default as demander_45un_45devisbj4dAReTr0Meta } from "/vercel/path0/pages/[market]/demander-un-devis.vue?macro=true";
import { default as _91uid_93n4B2DEFAEcMeta } from "/vercel/path0/pages/[market]/guide/[uid].vue?macro=true";
import { default as indexURcHieH9x6Meta } from "/vercel/path0/pages/[market]/guide/index.vue?macro=true";
import { default as indexU1zQQUh77nMeta } from "/vercel/path0/pages/[market]/index.vue?macro=true";
import { default as _91uid_935rC19qx6XRMeta } from "/vercel/path0/pages/[market]/lexique/[uid].vue?macro=true";
import { default as indexq8jfe2qVsuMeta } from "/vercel/path0/pages/[market]/lexique/index.vue?macro=true";
import { default as index6Z98Fbn1iyMeta } from "/vercel/path0/pages/actualites/[market]/[article]/index.vue?macro=true";
import { default as indexipMRhctl11Meta } from "/vercel/path0/pages/actualites/[market]/index.vue?macro=true";
import { default as indexVvvhaqiOxaMeta } from "/vercel/path0/pages/actualites/index.vue?macro=true";
import { default as indexDBbnZWdxZ3Meta } from "/vercel/path0/pages/contact/index.vue?macro=true";
import { default as indexEklWWVfbPCMeta } from "/vercel/path0/pages/decouvrez-nos-guides/index.vue?macro=true";
import { default as _91uid_932S1ELsNp3dMeta } from "/vercel/path0/pages/edito/[uid].vue?macro=true";
import { default as indexFbsvnz9l8uMeta } from "/vercel/path0/pages/espace-assure/index.vue?macro=true";
import { default as indexs8g6T0dsz8Meta } from "/vercel/path0/pages/faq/index.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as _91uid_93mkSlOpDQhMMeta } from "/vercel/path0/pages/landing-page/[uid].vue?macro=true";
import { default as legacy_45browsersrTaXmUyYJcMeta } from "/vercel/path0/pages/legacy-browsers.vue?macro=true";
import { default as indexu8D8pLyksLMeta } from "/vercel/path0/pages/plan-du-site/index.vue?macro=true";
import { default as indexaLTtB6dvNhMeta } from "/vercel/path0/pages/reclamation/index.vue?macro=true";
import { default as component_45stub5X4Ei38PMgMeta } from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stub5X4Ei38PMg } from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "prismic-preview",
    path: "/preview",
    component: () => import("/vercel/path0/node_modules/@nuxtjs/prismic/dist/runtime/PrismicPreview.vue")
  },
  {
    name: "market-product___fr",
    path: "/:market()/:product()",
    component: () => import("/vercel/path0/pages/[market]/[product]/index.vue")
  },
  {
    name: "market-demander-un-devis___fr",
    path: "/:market()/demander-un-devis",
    component: () => import("/vercel/path0/pages/[market]/demander-un-devis.vue")
  },
  {
    name: "market-guide-uid___fr",
    path: "/:market()/guide/:uid()",
    component: () => import("/vercel/path0/pages/[market]/guide/[uid].vue")
  },
  {
    name: "market-guide___fr",
    path: "/:market()/guide",
    component: () => import("/vercel/path0/pages/[market]/guide/index.vue")
  },
  {
    name: "market___fr",
    path: "/:market()",
    component: () => import("/vercel/path0/pages/[market]/index.vue")
  },
  {
    name: "market-lexique-uid___fr",
    path: "/:market()/lexique/:uid()",
    component: () => import("/vercel/path0/pages/[market]/lexique/[uid].vue")
  },
  {
    name: "market-lexique___fr",
    path: "/:market()/lexique",
    component: () => import("/vercel/path0/pages/[market]/lexique/index.vue")
  },
  {
    name: "actualites-market-article___fr",
    path: "/actualites/:market()/:article()",
    component: () => import("/vercel/path0/pages/actualites/[market]/[article]/index.vue")
  },
  {
    name: "actualites-market___fr",
    path: "/actualites/:market()",
    component: () => import("/vercel/path0/pages/actualites/[market]/index.vue")
  },
  {
    name: "actualites___fr",
    path: "/actualites",
    component: () => import("/vercel/path0/pages/actualites/index.vue")
  },
  {
    name: "contact___fr",
    path: "/contact",
    component: () => import("/vercel/path0/pages/contact/index.vue")
  },
  {
    name: "decouvrez-nos-guides___fr",
    path: "/decouvrez-nos-guides",
    component: () => import("/vercel/path0/pages/decouvrez-nos-guides/index.vue")
  },
  {
    name: "edito-uid___fr",
    path: "/edito/:uid()",
    component: () => import("/vercel/path0/pages/edito/[uid].vue")
  },
  {
    name: "espace-assure___fr",
    path: "/espace-assure",
    component: () => import("/vercel/path0/pages/espace-assure/index.vue")
  },
  {
    name: "faq___fr",
    path: "/faq",
    component: () => import("/vercel/path0/pages/faq/index.vue")
  },
  {
    name: "index___fr",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "landing-page-uid___fr",
    path: "/landing-page/:uid()",
    component: () => import("/vercel/path0/pages/landing-page/[uid].vue")
  },
  {
    name: "legacy-browsers___fr",
    path: "/legacy-browsers",
    meta: legacy_45browsersrTaXmUyYJcMeta || {},
    component: () => import("/vercel/path0/pages/legacy-browsers.vue")
  },
  {
    name: "plan-du-site___fr",
    path: "/plan-du-site",
    component: () => import("/vercel/path0/pages/plan-du-site/index.vue")
  },
  {
    name: "reclamation___fr",
    path: "/reclamation",
    component: () => import("/vercel/path0/pages/reclamation/index.vue")
  },
  {
    name: "faq-covid-19___fr",
    path: "/faq-covid-19",
    alias: ["/pages/edito/:uid"],
    component: () => import("/vercel/path0/pages/edito/[uid].vue")
  },
  {
    name: "april-courtage___fr",
    path: "/april-courtage",
    alias: ["/pages/edito/:uid"],
    component: () => import("/vercel/path0/pages/edito/[uid].vue")
  },
  {
    name: "avantage-assures___fr",
    path: "/avantage-assures",
    alias: ["/pages/edito/:uid"],
    component: () => import("/vercel/path0/pages/edito/[uid].vue")
  },
  {
    name: "parrainage-recompense___fr",
    path: "/parrainage-recompense",
    alias: ["/pages/edito/:uid"],
    component: () => import("/vercel/path0/pages/edito/[uid].vue")
  },
  {
    path: "/mon-formulaire-changer-assurance-de-pret-immobilier",
    name: "mon-formulaire-changer-assurance-de-pret-immobilier",
    redirect: "/landing-page/mon-formulaire-changer-assurance-de-pret-immobilier"
  },
  {
    path: "/ma-complementaire-sante-april-formulaire",
    name: "ma-complementaire-sante-april-formulaire",
    redirect: "/landing-page/ma-complementaire-sante-april-formulaire"
  },
  {
    path: "/parrainage",
    name: "parrainage",
    redirect: "/landing-page/parrainage"
  },
  {
    path: "/parrainage-april",
    name: "parrainage-april",
    redirect: "/landing-page/parrainage-april"
  },
  {
    path: "/parrainage-april-filleul",
    name: "parrainage-april-filleul",
    redirect: "/landing-page/parrainage-april-filleul"
  },
  {
    name: "contact-uid___fr",
    path: "/contact/:uid",
    alias: ["/pages/landing-page/:uid"],
    component: () => import("/vercel/path0/pages/landing-page/[uid].vue")
  },
  {
    name: "guide-uid___fr",
    path: "/guide/:uid?",
    alias: ["/pages/:market/guide/:uid"],
    component: () => import("/vercel/path0/pages/[market]/guide/[uid].vue")
  },
  {
    name: "market-submarket-product___fr",
    path: "/:market/:submarket/:product",
    alias: ["/pages/:market/:submarket/:product/index"],
    component: () => import("/vercel/path0/pages/[market]/[product]/index.vue")
  },
  {
    name: "market-product-demander-un-devis___fr",
    path: "/:market/:product/demander-un-devis",
    alias: ["/pages/:market/:product/demander-un-devis"],
    component: () => import("/vercel/path0/pages/[market]/demander-un-devis.vue")
  },
  {
    name: "market-submarket-product-demander-un-devis___fr",
    path: "/:market/:submarket/:product/demander-un-devis",
    alias: ["/pages/:market/:submarket/:product/demander-un-devis"],
    component: () => import("/vercel/path0/pages/[market]/demander-un-devis.vue")
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/sitemap.xml",
    component: component_45stub5X4Ei38PMg
  }
]