/**
 * To learn more about Link Resolving check out the Prismic documentation
 * https://prismic.io/docs/vuejs/beyond-the-api/link-resolving
 */

const rootEdito = [
  'faq-covid-19',
  'april-courtage',
  'avantage-assures',
  'parrainage-recompense',
];
const rootLanding = [
  'mon-formulaire-changer-assurance-de-pret-immobilier',
  'ma-complementaire-sante-april-formulaire',
  'parrainage',
  'parrainage-april',
  'parrainage-april-filleul',
];

export default function (doc) {
  const isPro = doc.tags.includes('pro');
  const type = doc.type;
  const market = doc.data?.market?.uid;
  const lexiMarket = market || doc.data?.lexicon?.data?.market?.uid;
  const submarket = doc.data?.submarket?.uid;
  const product = doc.data?.product?.uid;
  const pageType = doc.data?.type;
  const hasSiteRoute = 'sitePro' in doc;

  let proSubdomain;
  let partSubdomain;
  let bypassSSL;
  let domain;

  if (import.meta.client) {
    const config = useRuntimeConfig();
    proSubdomain = config.public.pro_subdomain;
    partSubdomain = config.public.part_subdomain;
    bypassSSL = config.public.bypass_ssl;
    domain = config.public.domain;
  } else {
    proSubdomain = process.env.PRO_SUBDOMAIN;
    partSubdomain = process.env.PART_SUBDOMAIN;
    bypassSSL = process.env.BYPASS_SSL;
    domain = process.env.DOMAIN;
  }

  // Protocol can be forced to http for local testing without valid certificate
  const protocol =
    bypassSSL && bypassSSL.toLowerCase() === 'true' ? 'http' : 'https';

  let proRoot = '/pro';
  let partRoot = '/';

  if (domain !== undefined) {
    if (partSubdomain === undefined && proSubdomain === undefined) {
      console.error(
        'PART_SUBDOMAIN or PRO_SUBDOMAIN environment variables are not defined'
      );
    }

    const partPrefix = `${protocol}://${
      partSubdomain !== undefined ? `${partSubdomain}.` : ''
    }${domain}`;
    const proPrefix = `${protocol}://${
      proSubdomain !== undefined ? `${proSubdomain}.` : ''
    }${domain}`;

    if (!hasSiteRoute) {
      proRoot = proPrefix;
      partRoot = partPrefix;
    } else if (doc.sitePro === true) {
      partRoot = partPrefix;
      proRoot = '/';
    } else {
      proRoot = proPrefix;
    }
  }

  let prefix = isPro ? proRoot : partRoot;
  if (prefix === '/') {
    prefix = '';
  }
  const notFound = `${prefix}/erreur-404`;

  if (doc.isBroken) {
    return notFound;
  }

  switch (type) {
    case 'homepage':
      return partRoot;

    case 'homepage_pro':
      return proRoot;

    case 'masterpage':
      return `${prefix}/${doc.uid}`;

    case 'product_page':
      if (!market) {
        return notFound;
      }
      return `${prefix}/${market}/${submarket ? `${submarket}/` : ''}${
        doc.uid
      }`;

    case 'clientpage':
      return `${prefix}/espace-assure`;

    case 'clientpage_pro':
      return `${prefix}/espace-assure`;

    case 'article':
      if (!market) {
        return notFound;
      }
      return `${prefix}/actualites/${market}/${doc.uid}`;

    case 'articles_list_market': {
      if (!market) {
        return notFound;
      }

      return `${prefix}/actualites/${market}`;
    }

    case 'articles_list_page':
      return `${prefix}/actualites`;

    case 'guide':
      if (isPro) {
        return `${prefix}/guide/${doc.uid}`;
      }
      if (!market) {
        return `${prefix}/assurance/guide/${doc.uid}`;
      }
      return `${prefix}/${market}/guide/${doc.uid}`;

    case 'guides_sitemap':
      return `${prefix}/decouvrez-nos-guides`;

    case 'page_index_guides':
      if (isPro) {
        return `${prefix}/guide`;
      }
      if (!market) {
        return `${prefix}/assurance/guide`;
      }
      return `${prefix}/${market}/guide`;

    case 'page_lexicon':
      if (!market) {
        return `${prefix}/assurance/lexique`;
      }
      return `${prefix}/${market}/lexique`;

    case 'page_definition':
      if (!lexiMarket) {
        return `${prefix}/assurance/lexique/${doc.uid}`;
      }
      return `${prefix}/${lexiMarket}/lexique/${doc.uid}`;

    case 'landingpage':
      if (rootLanding.includes(doc.uid)) {
        return `${prefix}/${doc.uid}`;
      }
      if (pageType === 'contact') {
        return `${prefix}/contact/${doc.uid}`;
      }
      if (pageType === 'estimate') {
        if (!market) {
          return notFound;
        }
        return `${prefix}/${market}/${submarket ? `${submarket}/` : ''}${
          product ? `${product}/` : ''
        }demander-un-devis`;
      }
      if (pageType === 'claim') {
        return `${prefix}/reclamation`;
      }
      return `${prefix}/landing-page/${doc.uid}`;

    case 'contact_page':
      return `${prefix}/contact`;

    case 'faq_page':
      return `${prefix}/faq`;

    case 'edito_page':
      if (rootEdito.includes(doc.uid)) {
        return `${prefix}/${doc.uid}`;
      }
      return `${prefix}/edito/${doc.uid}`;

    case 'sitemap':
      return `${prefix}/plan-du-site`;

    case 'search_page':
      return `${prefix}/recherche`;

    default:
      return notFound;
  }
}
