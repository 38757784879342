<template>
  <source
    v-if="srcset"
    :media="media"
    :data-srcset="isLazy ? srcset : null"
    :srcset="!isLazy ? null : srcset"
    :sizes="sizes"
  />
</template>

<script>
import {
  buildFluidSrcSet,
  buildFixedSrcSet,
  buildSingleSrcSet,
  commonProps,
} from './utils';

export default {
  props: {
    ...commonProps,
    media: {
      type: String,
      required: true,
    },
  },
  computed: {
    isFixed() {
      return this.$parent.fixed || this.fixed;
    },
    hasSingleSrc() {
      return this.$parent.singleSrc || this.singleSrc;
    },
    srcset() {
      if (!this.filename) {
        return null;
      }

      if (this.hasSingleSrc) {
        return buildSingleSrcSet(
          this.filename,
          this.$parent.width || this.width
        );
      }

      return this.isFixed
        ? buildFixedSrcSet(this.filename, this.$parent.width || this.width)
        : buildFluidSrcSet(this.filename, this.srcSetWidths, this.quality);
    },
  },
  methods: {
    isLazy() {
      return (
        (typeof this.$parent.lazy !== 'undefined' && this.$parent.lazy) ||
        this.lazy
      );
    },
  },
};
</script>
