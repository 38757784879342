export default defineNuxtRouteMiddleware((to, from) => {
  const config = useRuntimeConfig();
  const requestEvent = useRequestEvent();
  const nuxtApp = useNuxtApp();
  const isServer = typeof window == 'undefined';
  const path = isServer ? nuxtApp.ssrContext.url : window.location.pathname;
  const hostname = isServer
    ? requestEvent.headers.get('host')
    : window.location.host;

  if (to.name !== 'prismic-preview') {
    const isPro =
      hostname.indexOf(config.public.pro_subdomain) === 0 ||
      path.indexOf('/pro') === 0;

    if (isPro) {
      to.name = `pro__${to.name}`;
      to.path = !config.public.pro_subdomain ? `/pro${to.path}` : to.path;
    } else {
      to.name = `part__${to.name}`;
    }
  }
});
