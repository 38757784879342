import { adapter as imageAdapter } from '~/components/cloud-image/utils';
import { routerLinks } from '~/utils/fetch-links';
import callbackAdapter from '~/utils/freespee/webcallback-adapter';

let needfetch = false;

const isValidCallback = (entry) => {
  return (
    entry.type === 'webcallback' && entry.link.type === 'module_web_callback'
  );
};

const isValidLink = (entry) => {
  return entry.type === 'link' && entry.link;
};

const isValidCta = (entry) => {
  return entry.type === 'webcallback' || (entry.type === 'link' && !!entry.cta);
};

export default async (
  { smart_button },
  { $i18n, $prismic, $enhancedLinkSerializer, $store }
) => {
  const { data } = smart_button || {};

  if (!data) return;
  let callbackData;

  const entries = data.entries
    .filter((entry) => isValidLink(entry) || isValidCallback(entry))
    .map(({ label, icon, type, link }) => {
      const cta = type === 'link' ? $enhancedLinkSerializer(link) : null;

      if (cta) {
        const linkData = link.data;

        // Check if link is using market or product to force module fetch
        if (linkData && (linkData.market || linkData.product)) {
          needfetch = true;
        }
      }

      if (link.type === 'module_web_callback') {
        callbackData = link.data;
      }

      return {
        label,
        icon: imageAdapter(icon),
        cta,
        type,
      };
    })
    .filter((entry) => isValidCta(entry));

  // Fetch module again to have all the needed data to generate links
  if (needfetch) {
    const component = await $prismic.client.getByID(smart_button.id, {
      fetchLinks: routerLinks,
    });

    if (component && component?.data?.entries) {
      component.data.entries.forEach((entry, index) => {
        if (isValidLink(entry) && entries[index].cta) {
          entries[index].cta = $enhancedLinkSerializer(entry.link);
        }
      });
    }
  }

  const res = {
    cta: {
      label: data.cta_title,
      marketId: data.market,
    },
    entries,
  };

  callbackAdapter(callbackData, $i18n, $store);
  return res;
};
