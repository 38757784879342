export default defineNuxtPlugin((nuxtApp) => {
  window.axeptioSettings = {
    clientId: nuxtApp.$config.public.axeptio_client_id,
    userCookiesDomain: 'april.fr',
  };

  const script = document.createElement('script');
  script.src = 'https://static.axept.io/sdk-slim.js';
  script.async = true;
  document.getElementsByTagName('head')[0].appendChild(script);
});
