import * as prismicH from '@prismicio/helpers';
import linkResolver from '~/app/prismic/link-resolver';
import htmlSerializer from '~/app/prismic/html-serializer';

const isValidRichTextField = (input) => {
  // Field does not exist
  if (typeof input === 'undefined') {
    process.env.NODE_ENV !== 'production' &&
      console.warn(
        "Missing field. Please check you didn't mispell a field name"
      );
    return false;
  }

  // String is empty in Prismic
  if (!input.length || !input[0]?.text?.length) {
    return false;
  }
  return true;
};

export const richText = (input) => {
  if (!isValidRichTextField(input)) {
    return undefined;
  }
  return prismicH.asHTML(input, linkResolver, htmlSerializer);
};

export const replaceTag = (input, replacement = 'h2', needle = 'p') => {
  return input
    .replace(`<${needle}`, `<${replacement}`)
    .replace(`</${needle}`, `</${replacement}`);
};

export const replaceRichTextTag = (input, replacement = 'h2', needle = 'p') => {
  if (!isValidRichTextField(input)) {
    return undefined;
  }
  return prismicH
    .asHTML(input, linkResolver, htmlSerializer)
    .replace(`<${needle}`, `<${replacement}`)
    .replace(`</${needle}`, `</${replacement}`);
};

export const stripWrapperTag = (input) => {
  return input.replace(/^<p[^>]*>|<\/p>$/g, '');
};

export const stripRichTextWrapperTag = (input) => {
  if (typeof input === 'string') {
    return input;
  }
  if (!isValidRichTextField(input)) {
    return undefined;
  }

  return prismicH
    .asHTML(input, linkResolver, htmlSerializer)
    .replace(/^<p[^>]*>|<\/p>$/g, '');
};

export const asText = prismicH.asText;

export const stripHTMLTags = (input) => {
  return input.replace(/<[^>]*>?/gm, '');
};
