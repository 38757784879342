export const routerLinks = [
  'product_page.market',
  'product_page.submarket',
  'landingpage.market',
  'landingpage.product',
  'landingpage.submarket',
  'landingpage.type',
  'guide.market',
  'article.market',
  'page_lexicon.market',
];

export const marketLinks = [
  'masterpage.title',
  'masterpage.card',
  'market.title',
  'market.card_title',
  'market.card_image',
  'market.market_link',
  'masterpage.breadcrumb_title',
  'masterpage.freespee_parameter',
  'masterpage.primary_link',
  'masterpage.primary_link_label',
  'masterpage.ekomi_account',
];

export const productLinks = [
  'product_page.title',
  'product_page.tag',
  'product_page.starting_price',
  'product_page.price_frequency',
  'product_page.legal_reference',
  'product_page.market',
  'product_page.card',
  'product.title',
  'product.card_title',
  'product.card_image',
  'product.tag',
  'product.starting_price',
  'product.price_frequency',
  'product.legal_reference',
  'product.cta_primary_label',
  'product.cta_primary_link',
  'product.cta_secondary_label',
  'product.cta_secondary_link',
  'product.cta_secondary_document',
];

export const productPageLinks = ['product_page.product_data'];

export const guideLinks = [
  'guide.title',
  'guide.excerpt',
  'guide.date',
  'guide.market',
  'guide.reading_time',
  'guide.parent',
];

export const indexGuideLinks = ['page_index_guides.market'];

export const articleLinks = [
  'article.title',
  'article.image',
  'article.image_alt',
  'article.date',
  'article.market',
  'article.reading_time',
];

export const articlesListMarket = ['articles_list_market.market'];

export const contactLinks = [
  'contact.contact_subhead',
  'contact.contact_title',
  'contact.contact_phone_label',
  'contact.contact_phone_number',
  'contact.contact_description',
  'contact.contact_inline_description',
  'contact.contact_mention',
];

export const masterpageLinks = ['masterpage.market'];

export const landingpageLinks = [
  'landingpage.market',
  'landingpage.product',
  'landingpage.submarket',
];

export const dataVizExampleLinks = [
  'data_viz_example.title',
  'data_viz_example.description',
  'data_viz_example.total_cost',
  'data_viz_example.formulas',
];

export const warrantyCardLinks = [
  'warranty_card.title',
  'warranty_card.price',
  'warranty_card.price_frequency',
  'warranty_card.legal_reference',
  'warranty_card.warranties_title',
  'warranty_card.warranties',
  'warranty_card.options',
  'warranty_card.options_title',
];

export const callbackLinks = [
  'module_web_callback.callback_customer_id',
  'module_web_callback.callback_caller_id',
  'module_web_callback.callback_incoming_number',
  'module_web_callback.callback_phone_number',
  'module_web_callback.callback_opening_hours',
  'module_web_callback.callback_closed_days',
  'module_web_callback.callback_title',
  'module_web_callback.callback_subtitle',
  'module_web_callback.callback_intro',
  'module_web_callback.callback_intro_ooh',
  'module_web_callback.callback_confirmation_scheduled',
  'module_web_callback.callback_confirmation_asap',
  'module_web_callback.callback_submit_scheduled',
  'module_web_callback.callback_submit_asap',
  'module_web_callback.callback_switcher_label_asap',
  'module_web_callback.callback_switcher_label_scheduled',
  'module_web_callback.callback_confirmation_icon',
];

export const smartButtonLinks = [
  'smart_button.market',
  'smart_button.cta_title',
  'smart_button.entries',
  'smart_button.phone_number',
  'smart_button.callback_customer_id',
  'smart_button.callback_caller_id',
  'smart_button.callback_phone_number',
  'smart_button.callback_opening_hours',
  'smart_button.callback_closed_days',
  'smart_button.callback_title',
  'smart_button.callback_subtitle',
  'smart_button.callback_intro',
  'smart_button.callback_intro_ooh',
  'smart_button.callback_confirmation_scheduled',
  'smart_button.callback_confirmation_asap',
  'smart_button.callback_submit_scheduled',
  'smart_button.callback_submit_asap',
  'smart_button.callback_switcher_label_asap',
  'smart_button.callback_switcher_label_scheduled',
  'smart_button.callback_confirmation_icon',
  ...callbackLinks,
];

export const advantageLinks = [
  'advantage.advantage_title',
  'advantage.advantage_grey_background',
  'advantage.advantage_entries',
];

export const bannerLinks = [
  'banner.section_title',
  'banner.is_narrow',
  'banner.banner_entries',
];
export const accordionLinks = [
  'accordion_push.accordion_push_title',
  'accordion_push.accordion_push_isfaq',
  'accordion_push.accordion_items',
];

export const ratingsLinks = [
  'ratings.title',
  'ratings.text',
  'ratings.average_rating',
  'ratings.badge',
  'ratings.badge_alt',
  'ratings.view_all_link',
  'ratings.view_all_label',
  'ratings.ratings',
  'ratings.ekomi_api',
];

export const questionsAnswersLinks = [
  'module_questions_answers.title',
  'module_questions_answers.items',
];

export const comparadiseConfigLinks = [
  'module_comparadise_config.code',
  'module_comparadise_config.comparateur',
  'module_comparadise_config.utm_source',
  'module_comparadise_config.utm_campaign',
  'module_comparadise_config.utm_term',
  'module_comparadise_config.utm_medium',
  'module_comparadise_config.site_identifier',
  'module_comparadise_config.comparatorUrl',
];

export const crossSellTitleLinks = [
  'edito_page.breadcrumb_title',
  'landingpage.title',
  'article.title',
  'guide.title',
];
