import * as prismicH from '@prismicio/helpers';
import linkResolver from './link-resolver.js';

const getLabel = (elementLabel, children, content) => {
  if (elementLabel === 'infobulle') {
    return `<button data-tooltip="${children}">i</button>`;
  }
  if (elementLabel === 'exposant') {
    return `<sup class="${elementLabel}">${content}</sup>`;
  }

  const labelClass = elementLabel ? ` class="${elementLabel}"` : '';
  if (elementLabel.includes('anchor-')) {
    return `<span id="${elementLabel}" class="anchor">${children}</span>`;
  }

  return `<span ${labelClass}>${children}</span>`;
};

const getHyperlink = (element, content) => {
  let result = '';

  const url = prismicH.asLink(element.data, linkResolver);

  if (element.data.link_type === 'Document') {
    result = `<a class="cta cta-link cta-link__in-text" href="${url}" data-nuxt-link>${content}</a>`;
  } else {
    const target = element.data.target
      ? `target="${element.data.target}" rel="noopener"`
      : '';

    result = `<a class="cta cta-link cta-link__in-text" href="${url}" ${target}>${content}</a>`;
  }

  return result;
};

const getElementImage = (element) => {
  let result = `<img src="${element.url}" alt="${element.alt || ''}">`;

  if (element.linkTo) {
    const url = prismicH.asLink(element.linkTo, linkResolver);

    if (element.linkTo.link_type === 'Document') {
      result = `<a href="${url}" data-nuxt-link>${result}</a>`;
    } else {
      const target = element.linkTo.target
        ? `target="${element.linkTo.target}" rel="noopener"`
        : '';

      result = `<a href="${url}" ${target}>${result}¨</a>`;
    }
  }
  const wrapperClassList = [element.label || '', 'block-img'];

  result = `<p class="${wrapperClassList.join(' ')}">${result}</p>`;

  return result;
};

export default {
  heading1: ({ children }) => `<h1>${children}</h1>`,
  heading2: ({ children }) => `<h2>${children}</h2>`,
  heading3: ({ children }) => `<h3>${children}</h3>`,
  heading4: ({ children }) => `<h4>${children}</h4>`,
  heading5: ({ children }) => `<h5>${children}</h5>`,
  heading6: ({ children }) => `<h6>${children}</h6>`,
  paragraph: ({ children }) => `<p>${children}</p>`,
  preformatted: ({ children }) => `<pre>${children}</pre>`, // `<pre>${JSON.stringify(node.text)}</pre>` in the official prismic implementation
  strong: ({ children }) => `<b>${children}</b>`, // `<strong>${children}</strong>`, in the official prismic implementation
  em: ({ children }) => `<em>${children}</em>`,
  listItem: ({ children }) => `<li><p>${children}</p></li>`, // `<li>${children}</li>`, in the official prismic implementation
  oListItem: ({ children }) => `<li><p>${children}</p></li>`, // `<li>${children}</li>`, in the official prismic implementation
  list: ({ children }) => `<ul>${children}</ul>`,
  oList: ({ children }) => `<ol>${children}</ol>`,
  image: ({ node: element }) => {
    return getElementImage(element);
  },
  embed: ({ node }) => `
        <div data-oembed="${node.oembed.embed_url}"
          data-oembed-type="${node.oembed.type}"
          data-oembed-provider="${node.oembed.provider_name}">
          ${node.oembed.html}
        </div>
      `,
  hyperlink: ({ node: element, children: content }) => {
    return getHyperlink(element, content);
  },
  label: ({ node: element, children, text: content }) => {
    return getLabel(element.data.label, children, content);
  },
  span: ({ text }) => (text ? text.replace(/\n/g, '<br />') : ''),
};
