function setupLazy(context) {
  const setAttributes = (item) => {
    const { src, srcset } = item.dataset;
    try {
      if (src) {
        item.setAttribute('src', src);
      }
      if (srcset) {
        item.setAttribute('srcset', srcset);
      }
    } catch (e) {
      console.error('Error setting attributes', e);
    }
  };

  const isNativeLazyLoadingSupported = 'loading' in HTMLImageElement.prototype;

  const lazyDirective = {
    // Called when element is setup the first time
    mounted(el) {
      if (isNativeLazyLoadingSupported) {
        // If native lazy loading is supported, we want to redirect the "lazyload"
        // data-attributes to the actual attributes, and let the browser do the work
        let els;
        if (el.tagName === 'PICTURE') {
          els = Array.from(el.querySelectorAll('[data-srcset]'));
        } else {
          els = [el];
        }

        els.forEach((item) => {
          setAttributes(item);
        });

        return;
      }
      const script = document.createElement('script');
      script.src =
        'https://cdnjs.cloudflare.com/ajax/libs/lazysizes/5.1.2/lazysizes.min.js';
      document.body.appendChild(script);
    },
    // This is to handle any changes to the url that we might do after we've loaded
    // the element the first time
    updated(el) {
      // We need to manually set the img attributes if either:
      //   - we're using native lazy loading, or
      //   - the image has already been loaded by lozad, since it doesn't know to
      //     watch for changes, and so will miss any changes to data-src or data-srcset
      if (isNativeLazyLoadingSupported || el.classList.contains('lazyloaded')) {
        setAttributes(el);
      }
    },
  };

  context.vueApp.directive('lazy', lazyDirective);
}
export default defineNuxtPlugin((context) => {
  setupLazy(context);
});
