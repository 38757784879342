export default defineNuxtPlugin((nuxtApp) => {
  window.addEventListener(
    'click',
    (event) => {
      // If the clicked element doesn't have the right selector, bail
      if (
        !event.target.matches('a[data-nuxt-link]') ||
        event.target.href.includes('http')
      ) {
        return;
      }

      // Don't follow the link
      event.preventDefault();

      // Push link destination to router
      navigateTo({ path: event.target.pathname });
    },
    false
  );
});
