import { EKOMI_SET_REVIEWS, EKOMI_SET_ANSWERS } from '~/store/mutationTypes';

export const defaultAccount = 'asp';
export const getRating = (rating, i18n) => {
  if (!i18n) return;
  const ratingAsFloat = Number(rating).toFixed(1);
  return new Intl.NumberFormat(i18n.locale).format(ratingAsFloat);
};

const accounts = [
  {
    id: 'asp',
    name: 'APRIL Santé Prévoyance',
    ekomiId: '110335',
    interfaceId: '87627',
    interfacePassword: '46ef79acc84ace17717d35aef',
    formId: '8168',
    marketTag: null,
  },
  {
    id: 'international-care-fr',
    name: 'APRIL International Care - France',
    ekomiId: '71888',
    interfaceId: '61303',
    interfacePassword: '761ba2f3a5cfff270dd798b60',
    formId: '4874',
    marketTag: 'international',
  },
  {
    id: 'international-care-en',
    name: 'APRIL International Care - English',
    ekomiId: '71878',
    interfaceId: '61293',
    interfacePassword: '2c829111bc643c40f8055338d',
    formId: '24708',
    marketTag: 'international',
  },
  {
    id: 'moto',
    name: 'APRIL Moto',
    ekomiId: '76397',
    interfaceId: '65792',
    interfacePassword: '9c9772b90bc6cbc4c4aab91dc',
    formId: '36787',
    marketTag: 'moto',
  },
  {
    id: 'marine',
    name: 'APRIL Marine',
    ekomiId: '76398',
    interfaceId: '65793',
    interfacePassword: 'fb1ae50a80603a2415ab1dfce',
    formId: '36790',
    marketTag: 'marine',
  },
];

const getEkomiParams = (type, market) => {
  let params = {
    form_id: market.formId,
  };

  const answersParams = {
    published: 1,
    widthdrawn: 0,
    record_per_page: 3,
    show_moderated_reviews: 1,
    filter: 'positive',
  };

  if (type === 'answers') {
    params = {
      ...params,
      ...answersParams,
    };
  }

  return params;
};

export const getEkomiData = async (id, type, context) => {
  const store = context.$store || context.store;
  const data = store.state.ekomi[type][id || defaultAccount];

  if (data) {
    return data;
  }

  const market = accounts.find(
    (market) => market.id === (id || defaultAccount)
  );
  const params = getEkomiParams(type, market);

  try {
    // TODO: using fetch here does not work. It should be a problem for Vercel and SSR
    const res = await useFetch('/api/ekomi', {
      params: {
        market,
        type,
        params,
      },
    });

    const { data } = res.data.value;

    if (!data) {
      throw new Error(response.statusText);
    }

    if (type === 'reviews') {
      await store.commit(`ekomi/${EKOMI_SET_REVIEWS}`, {
        market: market.id,
        reviews: data,
      });

      return data;
    } else if (type === 'answers') {
      await store.commit(`ekomi/${EKOMI_SET_ANSWERS}`, {
        market: market.id,
        answers: data.result,
      });

      return data.result;
    }
  } catch (err) {
    console.error(err);
    return null;
  }
};
