import { useStore } from 'vuex';
import { isProRoute } from '~/utils/helpers/data';
import linkSerializer from '../../app/prismic/link-serializer';

export default defineNuxtPlugin(({ provide, ...context }) => {
  const route = useRoute();
  const store = useStore();
  const requestEvent = useRequestEvent();
  const isPro = isProRoute(context, requestEvent);

  provide('enhancedLinkSerializer', (link, isDocument) =>
    linkSerializer(link, isPro, isDocument, route, store)
  );
});
