import {
  FREESPEE_SET_STATIC_DATA,
  FREESPEE_SET_DYNAMIC_DATA,
  FREESPEE_SET_CALLBACKS,
} from './mutationTypes';

export const state = () => ({
  data: {
    static: null,
    dynamic: { callbacks: [] },
  },
  callbacks: [],
});

export const mutations = {
  [FREESPEE_SET_STATIC_DATA](mutationState, data) {
    mutationState.data.static = data;
  },

  [FREESPEE_SET_DYNAMIC_DATA](mutationState, data) {
    mutationState.data.dynamic = data;
  },

  [FREESPEE_SET_CALLBACKS](mutationState, data) {
    mutationState.callbacks = data;
  },
};
