<template>
  <div :style="style" :class="[sliceTopHeader && '__with-top-header']">
    <template v-for="(slice, index) in slices">
      <hero-push-perso
        v-if="slice.slice_type === 'hero_push_perso' && slice.data"
        :key="'slice-hero-' + index"
        :slice="slice"
        :data="data"
        v-bind="attrs[index]"
      />
      <hero-generic
        v-else-if="slice.slice_type === 'hero_generic' && slice.data"
        :key="'slice-' + index"
        :slice="slice"
        :data="data"
        v-bind="attrs[index]"
      />
      <accordion-push-perso
        v-else-if="slice.slice_type === 'accordion_push_perso' && slice.data"
        :key="'slice-accordion-push-hero' + index"
        :slice="slice"
        :data="data"
        v-bind="attrs[index]"
      />
      <advantages-push-perso
        v-else-if="slice.slice_type === 'advantages_push_perso' && slice.data"
        :key="'slice-advantages-push-hero' + index"
        :slice="slice"
        :data="data"
        v-bind="attrs[index]"
      />
      <banner-push-perso
        v-else-if="slice.slice_type === 'banner_push_perso' && slice.data"
        :key="'slice-banner-' + index"
        :slice="slice"
        :data="data"
        v-bind="attrs[index]"
      />
      <featured-block-perso
        v-else-if="slice.slice_type === 'featured_block_perso' && slice.data"
        :key="'slice-block-perso' + index"
        :slice="slice"
        :data="data"
        v-bind="attrs[index]"
      />
      <LazyHydrationWrapper
        v-else
        :key="'slices-' + index"
        v-bind="attrs[index]"
      >
        <accordion-push
          v-if="slice.slice_type === 'accordion_push' && slice.data"
          :slice="slice"
          :data="data"
        />
        <advantages-push
          v-else-if="slice.slice_type === 'advantages_push' && slice.data"
          :slice="slice"
          :data="data"
        />
        <article-head
          v-else-if="
            ['article_head', 'definition_head'].includes(slice.slice_type) &&
            slice.data
          "
          :slice="slice"
          :data="data"
          :style="`
            position: relative;
            z-index: ${slices.length - index};`"
        />
        <banner-push
          v-else-if="slice.slice_type === 'banner_push' && slice.data"
          :slice="slice"
          :data="data"
        />
        <breadcrumbs
          v-else-if="slice.slice_type === 'breadcrumbs' && slice.data"
          :slice="slice"
          :data="data"
        />
        <cta-list
          v-else-if="slice.slice_type === 'cta_list' && slice.data"
          :slice="slice"
          :data="data"
        />
        <contact-configurator
          v-else-if="slice.slice_type === 'contact_configurator' && slice.data"
          :slice="slice"
          :data="data"
        />
        <contact-faq
          v-else-if="slice.slice_type === 'contact_faq' && slice.data"
          :slice="slice"
          :data="data"
        />
        <contact-push
          v-else-if="slice.slice_type === 'contact_push' && slice.data"
          :slice="slice"
          :data="data"
        />
        <data-viz
          v-else-if="slice.slice_type === 'data_viz' && slice.data"
          :slice="slice"
          :data="data"
        />
        <documents-list
          v-else-if="slice.slice_type === 'documents_list' && slice.data"
          :slice="slice"
          :data="data"
        />
        <featured-block
          v-else-if="slice.slice_type === 'featured_block' && slice.data"
          :slice="slice"
          :data="data"
        />
        <form-simple
          v-else-if="slice.slice_type === 'form_simple' && slice.data"
          :slice="slice"
          :data="data"
          @formSuccess="onFormSuccess"
        />
        <form-steps-simple
          v-else-if="slice.slice_type === 'form_steps_simple' && slice.data"
          :slice="slice"
          :data="data"
        />
        <form-push
          v-else-if="slice.slice_type === 'form_push' && slice.data"
          :slice="slice"
          :data="data"
        />
        <form-steps-push
          v-else-if="slice.slice_type === 'form_steps_push' && slice.data"
          :slice="slice"
          :data="data"
        />
        <edito-carousel
          v-else-if="slice.slice_type === 'edito_carousel' && slice.data"
          :slice="slice"
          :data="data"
        />
        <edito-cta
          v-else-if="slice.slice_type === 'edito_cta' && slice.data"
          :slice="slice"
          :data="data"
        />
        <edito-rich-text
          v-else-if="slice.slice_type === 'edito_rich_text' && slice.data"
          :slice="slice"
          :data="data"
          :style="`
            position: relative;
            z-index: ${slices.length - index};`"
        />
        <edito-table
          v-else-if="slice.slice_type === 'edito_table' && slice.data"
          :slice="slice"
          :data="data"
        />
        <find-contact-push
          v-else-if="slice.slice_type === 'find_contact_push' && slice.data"
          :slice="slice"
          :data="data"
        />
        <hero-push
          v-else-if="slice.slice_type === 'hero_push' && slice.data"
          :slice="slice"
          :data="data"
        />
        <iframe-block
          v-else-if="slice.slice_type === 'iframe_block' && slice.data"
          :slice="slice"
          :data="data"
        />
        <legal-mentions
          v-else-if="slice.slice_type === 'legal_mentions' && slice.data"
          :slice="slice"
          :data="data"
        />
        <mosaic-push
          v-else-if="slice.slice_type === 'mosaic_push' && slice.data"
          :slice="slice"
          :data="data"
        />
        <picto-carousel
          v-else-if="slice.slice_type === 'picto_carousel' && slice.data"
          :slice="slice"
          :data="data"
        />
        <quote-push
          v-else-if="slice.slice_type === 'quote_push' && slice.data"
          :slice="slice"
          :data="data"
        />
        <related-guides
          v-else-if="slice.slice_type === 'related_guides' && slice.data"
          :slice="slice"
          :data="data"
        />
        <ratings-push
          v-else-if="slice.slice_type === 'ratings_push' && slice.data"
          :slice="slice"
          :data="data"
        />
        <seo-push
          v-else-if="slice.slice_type === 'seo_push' && slice.data"
          :slice="slice"
          :data="data"
        />
        <social-media
          v-else-if="slice.slice_type === 'social_media' && slice.data"
          :slice="slice"
          :data="data"
        />
        <youtube-video
          v-else-if="slice.slice_type === 'youtube_video' && slice.data"
          :slice="slice"
          :data="data"
        />
        <web-callback-standalone
          v-else-if="
            slice.slice_type === 'web_callback_standalone' && slice.data
          "
          :slice="slice"
          :data="data"
        />
        <answers-push
          v-else-if="slice.slice_type === 'answers_push' && slice.data"
          :slice="slice"
          :data="data"
        />
        <buyapowa-push
          v-else-if="slice.slice_type === 'buyapowa_push' && slice.data"
          :slice="slice"
          :data="data"
        />
        <hook-push
          v-else-if="slice.slice_type === 'hook_push' && slice.data"
          :slice="slice"
          :data="data"
        />
        <hook-push-v2
          v-else-if="slice.slice_type === 'hook_push_v2' && slice.data"
          :slice="slice"
          :data="data"
        />
        <anchor-list
          v-else-if="slice.slice_type === 'anchor_list' && slice.data"
          :slice="slice"
          :data="data"
        />
        <calculator
          v-else-if="slice.slice_type === 'calculator' && slice.data"
          :slice="slice"
          :data="data"
        />
        <highlight-v2
          v-else-if="slice.slice_type === 'highlight_v2' && slice.data"
          :slice="slice"
          :data="data"
        />
        <edito-image-v2
          v-else-if="slice.slice_type === 'edito_image_v2' && slice.data"
          :slice="slice"
          :data="data"
        />
        <quote
          v-else-if="slice.slice_type === 'quote' && slice.data"
          :slice="slice"
          :data="data"
        />
        <hook-push-v2-simple
          v-else-if="slice.slice_type === 'hook_push_v2_simple' && slice.data"
          :slice="slice"
          :data="data"
        />
      </LazyHydrationWrapper>
    </template>
  </div>
</template>

<script>
import { LazyHydrationWrapper } from 'vue3-lazy-hydration';
import { defineAsyncComponent } from 'vue';
import { LAYOUT_SET_TOP_HEADER } from '~/store/mutationTypes';

export default {
  components: {
    LazyHydrationWrapper,
    AdvantagesPushPerso: defineAsyncComponent(() =>
      import('./advantages-push-perso')
    ),
    AdvantagesPush: defineAsyncComponent(() => import('./advantages-push')),
    AccordionPush: defineAsyncComponent(() => import('./accordion-push')),
    AccordionPushPerso: defineAsyncComponent(() =>
      import('./accordion-push-perso')
    ),
    ArticleHead: defineAsyncComponent(() => import('./article-head')),
    BannerPushPerso: defineAsyncComponent(() => import('./banner-push-perso')),
    BannerPush: defineAsyncComponent(() => import('./banner-push')),
    Breadcrumbs: defineAsyncComponent(() => import('./breadcrumbs')),
    ContactConfigurator: defineAsyncComponent(() =>
      import('./contact-configurator')
    ),
    ContactFaq: defineAsyncComponent(() => import('./contact-faq')),
    ContactPush: defineAsyncComponent(() => import('./contact-push')),
    CtaList: defineAsyncComponent(() => import('./cta-list')),
    DataViz: defineAsyncComponent(() => import('./data-viz')),
    DocumentsList: defineAsyncComponent(() => import('./documents-list')),
    EditoCarousel: defineAsyncComponent(() => import('./edito-carousel')),
    EditoCta: defineAsyncComponent(() => import('./edito-cta')),
    EditoRichText: defineAsyncComponent(() => import('./edito-rich-text')),
    EditoTable: defineAsyncComponent(() => import('./edito-table')),
    FindContactPush: defineAsyncComponent(() => import('./find-contact-push')),
    FeaturedBlock: defineAsyncComponent(() => import('./featured-block')),
    FeaturedBlockPerso: defineAsyncComponent(() =>
      import('./featured-block-perso')
    ),
    FormSimple: defineAsyncComponent(() => import('./form-simple')),
    FormStepsSimple: defineAsyncComponent(() => import('./form-steps-simple')),
    FormPush: defineAsyncComponent(() => import('./form-push')),
    FormStepsPush: defineAsyncComponent(() => import('./form-steps-push')),
    HeroPush: defineAsyncComponent(() => import('./hero-push')),
    HeroPushPerso: defineAsyncComponent(() => import('./hero-push-perso')),
    IframeBlock: defineAsyncComponent(() => import('./iframe-block')),
    LegalMentions: defineAsyncComponent(() => import('./legal-mentions')),
    MosaicPush: defineAsyncComponent(() => import('./mosaic-push')),
    RatingsPush: defineAsyncComponent(() => import('./ratings-push')),
    PictoCarousel: defineAsyncComponent(() => import('./picto-carousel')),
    QuotePush: defineAsyncComponent(() => import('./quote-push')),
    RelatedGuides: defineAsyncComponent(() => import('./related-guides')),
    SeoPush: defineAsyncComponent(() => import('./seo-push')),
    SocialMedia: defineAsyncComponent(() => import('./social-media')),
    YoutubeVideo: defineAsyncComponent(() => import('./youtube-video')),
    WebCallbackStandalone: defineAsyncComponent(() =>
      import('./web-callback-standalone')
    ),
    AnswersPush: defineAsyncComponent(() => import('./answers-push')),
    BuyapowaPush: defineAsyncComponent(() => import('./buyapowa-push')),
    HookPush: defineAsyncComponent(() => import('./hook-push')),
    HookPushV2: defineAsyncComponent(() => import('./hook-push-v2')),
    AnchorList: defineAsyncComponent(() => import('./anchor-list')),
    Calculator: defineAsyncComponent(() => import('./calculator')),
    HighlightV2: defineAsyncComponent(() => import('./highlight-v2')),
    EditoImageV2: defineAsyncComponent(() => import('./edito-image-v2')),
    Quote: defineAsyncComponent(() => import('./quote')),
    HeroGeneric: defineAsyncComponent(() => import('./hero-generic')),
    HookPushV2Simple: defineAsyncComponent(() =>
      import('./hook-push-v2-simple')
    ),
  },
  props: {
    slices: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Object,
      default: null,
    },
  },
  computed: {
    attrs: function () {
      return this.slices.map((index) => ({
        [index === 0 ? 'when-idle' : 'when-visible']: true,
      }));
    },
    sliceTopHeader: function () {
      return this.slices?.find((slice) => slice.slice_type === 'top_header');
    },
    topHeaderHeight: function () {
      return this.$store.state.layout.topHeader?.height || 0;
    },
    style: function () {
      return {
        '--top-header-height': `${this.topHeaderHeight}px`,
      };
    },
  },
  mounted: function () {
    if (this.sliceTopHeader) {
      this.$store.commit(`layout/${LAYOUT_SET_TOP_HEADER}`, {
        ...this.sliceTopHeader.data,
        isHidden: false,
      });
    } else {
      this.$store.commit(`layout/${LAYOUT_SET_TOP_HEADER}`, null);
    }
  },
  methods: {
    onFormSuccess() {
      this.$emit('formSuccess');
    },
  },
};
</script>

<style lang="scss">
.__with-top-header {
  margin-top: var(--top-header-height);

  @media #{$mq-small} {
    margin-top: 0;
  }
}
</style>
