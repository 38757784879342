<template>
  <picture v-lazy="lazy ? true : null">
    <slot />
  </picture>
</template>

<script>
export default {
  props: {
    lazy: {
      type: Boolean,
      required: false,
      default: true,
    },
    fixed: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>
