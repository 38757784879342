import { plugin as adUIPlugin } from 'UI/store/ui/plugin';
import sanitizeFooter from './sanitizers/footer';
import getHeaderV2 from '~/utils/helpers/get-header-v2';
import { routerLinks } from '~/utils/fetch-links';
import * as prismic from '@prismicio/client';

import {
  LAYOUT_SET_FOOTER_DATA,
  LAYOUT_SET_HEADER_DATA,
  LAYOUT_SET_PRO,
  SET_REDIRECTIONS,
} from './mutationTypes';
import { isProRoute } from '~/utils/helpers/data';

export const plugins = [adUIPlugin];

export const state = () => ({
  redirections: [],
});

export const mutations = {
  [SET_REDIRECTIONS](mutationState, data) {
    mutationState.redirections = data;
  },
};

export const actions = {
  async nuxtServerInit({ commit }, context) {
    const { $prismic } = context;
    const nuxtApp = useNuxtApp();
    const requestEvent = useRequestEvent();

    // Theme
    const isPro = isProRoute(nuxtApp, requestEvent);
    commit(`layout/${LAYOUT_SET_PRO}`, isPro);

    // Redirections
    try {
      const redirectionsData = await $prismic.get({
        filters: [prismic.filter.at('document.type', 'redirect')],
      });
      const redirections = redirectionsData.results.map((result) => ({
        from: result.data.from,
        to: result.data.to,
      }));
      commit(`${SET_REDIRECTIONS}`, redirections);
    } catch (e) {
      console.warn('An error occured during redirections fetch.', e);
    }

    // Header Data
    try {
      const header = await getHeaderV2($prismic, context, isPro);
      commit(`layout/${LAYOUT_SET_HEADER_DATA}`, header);
    } catch (e) {
      console.warn('An error occured during header fetch.', e);
    }

    // Footer Data
    try {
      const { data: footerData } = await $prismic.getByUID(
        'footer',
        isPro ? 'footer-pro' : 'footer',
        {
          fetchLinks: routerLinks,
        }
      );

      commit(
        `layout/${LAYOUT_SET_FOOTER_DATA}`,
        sanitizeFooter(footerData, context)
      );
    } catch (e) {
      console.warn('An error occured during footer fetch.', e);
    }
  },
};
