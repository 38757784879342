import { FREESPEE_SET_STATIC_DATA } from '~/store/mutationTypes';
import { stripRichTextWrapperTag } from '~/utils/html';
import { toCamelCase } from '~/utils/case-transform';
import { adapter as imageAdapter } from '~/components/cloud-image/utils';
import forms18n from '~/i18n/messages/fr-fr.forms.json';
import mergeI18n from '../merge-i18n';

const timeFormat = /^([0-1]\d|2[0-3]):?([0-5]\d)$/;

const testOpeningHours = (openingHours) => {
  return (
    timeFormat.test(openingHours.start) &&
    timeFormat.test(openingHours.end) &&
    Object.values(openingHours).includes(true)
  );
};

export default (data, i18n, store) => {
  if (!data) {
    store.commit(`freespee/${FREESPEE_SET_STATIC_DATA}`, null);
    return;
  }
  mergeI18n(i18n, forms18n);

  const webcallback = Object.keys(data).reduce((obj, key) => {
    const newKey = toCamelCase(key.replace(/callback_/, ''));
    if (data[key]?.[0]?.type === 'paragraph') {
      obj[newKey] = stripRichTextWrapperTag([data[key][0]]);
    } else {
      obj[newKey] = data[key];
    }
    return obj;
  }, {});

  const openingHours = webcallback.openingHours.filter((openingHours) =>
    testOpeningHours(openingHours)
  );

  if (!openingHours.length) {
    return;
  }

  const webcallbackData = {
    ...webcallback,
    switcherLabels: [
      webcallback.switcherLabelAsap,
      webcallback.switcherLabelScheduled,
    ],
    introOutsideOpeningHours: webcallback.introOoh,
    confirmationIcon: webcallback.confirmationIcon
      ? imageAdapter(webcallback.confirmationIcon)
      : null,
    phoneInput: {
      id: 'phone',
      label: i18n.t('webcallback.phone-input-label'),
      type: 'tel',
      mask: {
        mask: '00 00 00 00 00',
      },
    },
  };

  ['switcherLabelAsap', 'switcherLabelScheduled', 'introOoh'].forEach(
    (key) => delete webcallback[key]
  );

  store.commit(`freespee/${FREESPEE_SET_STATIC_DATA}`, webcallbackData);
};
