import { getDocumentType, getSiteType } from '~/utils/get-type';
import getLinkWithParams from '~/utils/get-link-params';
import linkResolver from '~/app/prismic/link-resolver';

export default (context, document, { route, requestEvent }) => {
  const templateType = document.type;

  // Check if the document is particulier or pro
  const { tags } = document;

  const documentType = getDocumentType(tags);

  //Check if the site is currently pro or particulier
  const currentSite = getSiteType(requestEvent?.node.req);

  // Generate propper document URL
  const documentUrl = getLinkWithParams(document, route);

  if (currentSite !== documentType) {
    navigateTo(documentUrl, { external: true, redirectCode: 301 });
  }

  if (
    templateType === 'faq_page' ||
    templateType === 'articles_list_page' ||
    templateType === 'articles_list_market'
  ) {
    return documentUrl;
  }

  return linkResolver(document);
};
